import React from "react"
import { Link } from "gatsby"
import cover from "../images/Asset/cover.svg"
import card1 from "../images/Asset/card-image-1.png"
import card2 from "../images/Asset/card-image-2.png"
import indexStyle from "../components/index.module.css"
import classNames from "classnames"
import MaterialIcon from "./materialIcon"

export default () => {
  return (
    <>
      <section className={indexStyle.main_hero}>
        <div className={indexStyle.main_hero_inner}>
          <div className={indexStyle.main_hero_img_wrap}>
            <img src={cover} className={indexStyle.main_hero_img} alt="" />
          </div>
          <div
            className={classNames(
              indexStyle.main_hero_text_wrap,
              indexStyle.centering
            )}
          >
            <h2 className={indexStyle.main_hero_text}>
              <span className={indexStyle.main_hero_text_inner}>
                チームに入りたい
                <br
                  className={indexStyle.main_hero_text_blake3}
                  aria-hidden="true"
                />
                と願う
                <br
                  className={indexStyle.main_hero_text_blake2}
                  aria-hidden="true"
                />
                <em className={indexStyle.main_hero_text_emphasis}>
                  すべての人を
                  <br
                    className={indexStyle.main_hero_text_blake3}
                    aria-hidden="true"
                  />
                  受け入れる社会
                </em>
                を創る
              </span>
            </h2>
          </div>
        </div>
      </section>
      <section
        className={classNames(
          indexStyle.main_goal,
          indexStyle.centering,
          indexStyle.flex_column
        )}
      >
        <div className={indexStyle.main_goal_title_wrap} v>
          <h2 className={indexStyle.main_goal_title}>
            サイボウズが目指す
            <br aria-hidden="true" />
            <em className={indexStyle.text_emphasis}>アクセシビリティ</em>
          </h2>
        </div>
        <div className={indexStyle.main_goal_contents}>
          <p>
            サイボウズは
            <em className={indexStyle.text_emphasis}>
              「チームワークあふれる社会を創る」
            </em>
            を企業理念とし、グループウェアの開発を進めています。
          </p>
          <p>
            その活動の中で、
            <em className={indexStyle.text_emphasis}>
              アクセシビリティを「ユーザがチームにアクセスする能力」である
            </em>
            ととらえています。
          </p>
          <p>
            誰もが
            <em className={indexStyle.text_emphasis}>
              「チームに参加・貢献したい」という願いを叶えられる社会
            </em>
            を創ることが私たちの目標です。
          </p>
        </div>
      </section>
      <section className={indexStyle.main_activity}>
        <div className={indexStyle.centering}>
          <h2 className={indexStyle.main_activity_title}>活動紹介</h2>
          <ul
            className={classNames(
              indexStyle.main_activity_contents,
              indexStyle.flex_column
            )}
          >
            <li className={indexStyle.main_activity_contents_item}>
              <Link
                to="/products"
                className={indexStyle.main_activity_contents_item_link}
              >
                <div
                  className={indexStyle.main_activity_contents_item_img_wrap}
                >
                  <img
                    className={indexStyle.main_activity_contents_item_img}
                    alt=""
                    src={card1}
                  />
                </div>
                <div className={indexStyle.main_activity_contents_item_body}>
                  <h3 className={indexStyle.main_activity_contents_item_title}>
                    製品アクセシビリティ情報
                  </h3>
                  <p
                    className={
                      indexStyle.main_activity_contents_item_description
                    }
                  >
                    サイボウズ製品をお使いの方・導入を検討されている方に向けてサイボウズの製品のアクセシビリティ情報を掲載しています。
                  </p>
                </div>
              </Link>
            </li>
            <li className={indexStyle.main_activity_contents_item}>
              <Link
                to="/training"
                className={indexStyle.main_activity_contents_item_link}
              >
                <div
                  className={indexStyle.main_activity_contents_item_img_wrap}
                >
                  <img
                    className={indexStyle.main_activity_contents_item_img}
                    alt=""
                    src={card2}
                  />
                </div>
                <div className={indexStyle.main_activity_contents_item_body}>
                  <h3 className={indexStyle.main_activity_contents_item_title}>
                    アクセシビリティ啓発活動
                  </h3>
                  <p
                    className={
                      indexStyle.main_activity_contents_item_description
                    }
                  >
                    チームにアクセシビリティを広げていきたいと考えている方に向けてサイボウズで行っている啓発活動の資料やアーカイブを掲載しています。
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section
        className={classNames(indexStyle.main_cooperate, indexStyle.centering)}
      >
        <h2 className={indexStyle.main_cooperate_title}>
          アクセシビリティ改善にご協力ください
        </h2>
        <div
          className={classNames(
            indexStyle.main_cooperate_contents,
            indexStyle.flex_column
          )}
        >
          <div className={indexStyle.main_cooperate_contents_description}>
            <p className={indexStyle.main_cooperate_contents_text}>
              サイボウズ製品のアクセシビリティに関する意見や感想、アイデアを募集しています。
            </p>
            <p className={indexStyle.main_cooperate_contents_text}>
              いただいた内容は、製品のアクセシビリティ改善の参考にさせていただきます。
            </p>
            <br aria-hidden="true" />
            <p className={indexStyle.main_cooperate_contents_text}>
              {" "}
              ぜひ、サイボウズ製品のアクセシビリティ改善へのご協力をお待ちしています。
            </p>
          </div>
          <div className={indexStyle.main_cooperate_contents_button_wrap}>
            <a
              href="https://form.kintoneapp.com/public/form/show/57b7ffcb69273fcfbedcb7862cfe19aaba26c145dae9e8bae5ba702c7d21d0ae"
              target="_blank"
              rel="noopener noreferrer"
              className={indexStyle.main_cooperate_contents_button}
            >
              {" "}
              アクセシビリティ改善に協力する
              <MaterialIcon iconName="open_in_new" text="外部リンク" />
            </a>
          </div>
        </div>
      </section>
    </>
  )
}
